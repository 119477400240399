"use client";

import ErrorPageContent from "@/components/home/ErrorPageContent/ErrorPageContent";

export default function GlobalError({ error }: { error: Error }) {
  return (
    <html>
      <body>
        <main>
          <ErrorPageContent
            title="Something went wrong accessing Custom Actions."
            detail={error.message}
          />
        </main>
      </body>
    </html>
  );
}
